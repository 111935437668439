import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// import components
import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderImage from '../components/headerImage'

// import templates
import BasicLayout from '../templates/basic-layout'

const seo_description = `Personal training with Ty Paul offers proven methods and techniques to help improve performance, working with Premier League footballers such as Yves Bissouma, Jurgan Locadia and Steven Alzate`
const seo_keywords = `Yves Bissouma, improve performance, Sports Specific Training, Premier League footballers, NHS approved,  Danique Kerkdijk, Co-ordination, personal training, Reaction Time, affordable prices, Core Stability, , Recovery Rate, personal training Hove, personal training Brighton, online personal training, Hove personal trainer, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton, Jurgan Locadia`

const Bootcamp = () => {
  const data = useStaticQuery(query)
  const { strapiBootcamp = {} } = data
  const { title = '', description = '', SEO = '' } = strapiBootcamp

  const { page_description = seo_description, page_keywords = seo_keywords, page_title = title } = SEO

  return (
    <Layout>
      <Seo title={page_title} description={page_description} keywords={page_keywords} />
      <HeaderImage src={require(`../media/images/bootcamp_background.jpg`).default} title={title} />
      <BasicLayout pageName={title} description={description} rightImage={''} images={[]} />
    </Layout>
  )
}

const query = graphql`
  {
    strapiBootcamp {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
  }
`

export default Bootcamp
